import { keyBy, get } from 'lodash';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Edit, Error } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Delete from './Delete';
import EditButton from './EditButton';
import Ticker from './Ticker';
import Result from './Result';
import CalculationStatus from '../ContribWithdrawField/CalculationStatus';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  assetElement: {
    flexBasis: '75px',
    width: '100%',
    alignItems: 'center',
    marginTop: '5px',
    marginLeft: '1%',
    marginRight: '1%',
  },
  titleStatus: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: 'black',
    marginLeft: '10px',
    maxWidth: '480px',
  },
  textIcons: {
    transform: 'scale(0.8)',
    marginBottom: '-4px',
  },
}));

export default function AssetList() {
  const { assetData } = useSelector(({ assets }) => assets);
  const { resultData } = useSelector(({ results }) => results);
  const keyedResults = keyBy(resultData, 'name');

  const classes = useStyles();

  return (
    <div>
      <div>
        <div className={classes.title}>
          <Typography variant='h6'>
            Assets
          </Typography>
          <ul style={{ marginTop: '0px' }}>
            <li>Click "ADD ASSET" to add an asset to the list.</li>
            <li>Click <Edit className={classes.textIcons} /> or <Error color='error' className={classes.textIcons} />
              to edit an existing asset.</li>
            <li>The <Error color='error' className={classes.textIcons} /> icon indicates there is an error and the asset
              must be edited to fix it.</li>
            <li>Click <DeleteIcon className={classes.textIcons} /> to remove an asset from the list.</li>
            <li>Suggested transactions will appear at the right hand side of each card after clicking "CALCULATE".</li>
          </ul>
        </div>
        <CalculationStatus />
      </div>
      {assetData.map((props, index) => {
        const result = get(keyedResults, props.name, {});

        return (
          <Card key={`asset-${index}`} className={classes.assetElement} elevation={3}>
            <CardContent className={classes.content}>
              <Delete index={index} />
              <EditButton
                index={index}
              />
              <Ticker name={props.name} />
              <Result
                name={props.name}
                continuous={props.continuous}
                deltaBalance={result.deltaBalance}
                deltaShares={result.deltaShares}
                price={result.requestPrice}
              />
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}
