import { Divider, makeStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

import MenuItem from '../Template/MuiMenuItem';
import { fileDownload } from '../../utils/io';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  exportText: {
    paddingLeft: '10px',
  }
}));

export default function Export(props) {
  const classes = useStyles();

  const { setAnchorEl } = props;

  const handleClick = async () => {
    const content = Object.keys(window.localStorage).reduce((store, key) => {
      let json;
      try {
        json = JSON.parse(window.localStorage.getItem(key));
      } catch {
        json = window.localStorage.getItem(key);
      }
      return { ...store, [key]: json };
    }, {});
    fileDownload(content, 'portfolio-data.json');
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <DownloadIcon />
        <span className={classes.exportText}>Export data</span>
      </MenuItem>
      <Divider className={classes.divider} />
    </>
  );
}