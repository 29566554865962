import { Box, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { roundOut, formatMoney } from '../../utils';

const useStyles = makeStyles(() => ({
  box: {
    marginTop: '10px',
  },
  surplusText: {
    color: 'rgb(0,200,0)',
  },
  deficitText: {
    color: 'rgb(200,0,0)',
  },
  passText: {
    color: 'rgb(0,0,0)',
  },
}));

export default function CalculationStatus() {
  const {
    remainder,
    withdrawn,
    lastCalculation,
    edited,
  } = useSelector(({ config }) => config);

  const classes = useStyles();

  let text = '';
  let textClass = classes.passText;
  if (remainder !== undefined) {
    if (remainder > 0.0001) {
      text = `Uncontributed balance of $${formatMoney(roundOut(remainder, 2))}`;
      textClass = classes.surplusText;
    } else if (remainder < -0.0001) {
      text = `Error: uncontributed balance of $${formatMoney(roundOut(remainder, 2))}`;
      textClass = classes.deficitText;
    } else {
      text = 'No uncontributed balance'
    }
  }

  if (withdrawn !== undefined) {
    if (withdrawn > 0.0001) {
      text = `Withdrew $${formatMoney(roundOut(withdrawn, 2))}`;
      textClass = classes.surplusText;
    } else if (withdrawn < -0.0001) {
      text = `Error: withdrawal of $${formatMoney(roundOut(withdrawn, 2))}`;
      textClass = classes.deficitText;
    } else if (withdrawn === null) {
      text = 'Cannot withdraw, insufficient funds';
      textClass = classes.deficitText;
    } else {
      text = 'No balance withdrawn';
    }
  }

  if (text.length > 0 && lastCalculation === 'rebalance') {
    text = `${text} after rebalance`;
  }

  if (text.length > 0) {
    text = `${text}.`;
  }

  return (
    <Box className={classes.box} alignContent='center'>
      <Typography
        className={textClass}
        align='right'
        variant='subtitle1'
        style={{ textDecoration: edited ? 'line-through' : 'none' }}
      >
        {text}
      </Typography>
    </Box>
  );
}
