import { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Info from "@material-ui/icons/Info";

import Menu from '../Template/MuiMenu';
import Instructions from './Instructions';
import About from './About';
import Support from './Support';

const useStyles = makeStyles(() => ({
  infoButton: {
    minWidth: '45px',
    maxWidth: '45x',
  },
  infoIcon: {
    fontSize: '35px',
    fill: 'rgb(205, 205, 205)',
  },
}));

export default function InfoMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Button className={classes.infoButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Info className={classes.infoIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Instructions setAnchorEl={setAnchorEl} />
        <Support setAnchorEl={setAnchorEl} />
        <About setAnchorEl={setAnchorEl} />
      </Menu>
    </>
  );
}