import React from 'react';

import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '90%',
    minWidth: '500px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    paddingRight: '5px',
  }
}));

export default function errorWrapper(Component, title, description) {
  return (props) => {
    const classes = useStyles();

    const [errors, setErrors] = React.useState(undefined);

    if (errors === undefined || errors.length === 0) {
      // No errors, proceed to render component.
      return <Component {...props} setErrors={setErrors} />
    }

    const onClose = () => {
      setErrors(undefined);
    }

    // Errors exist, display the error messages.
    return (
      <>
        <Component {...props} setErrors={setErrors} />
        <Dialog
          className={classes.dialog}
          open={Boolean(errors)}
          onClose={onClose}
        >
          <DialogTitle>
            <div className={classes.title}>
              <Error className={classes.errorIcon} color='error' />
              <span>{title || 'Error'}</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component={'span'}>
              {description || 'The following errors were detected:'}
              <ul>
                {errors.map((error, index) => <li key={`error-${index}`}>{error.message}</li>)}
              </ul>
            </DialogContentText>
            <DialogActions>
              <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}