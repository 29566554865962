import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Divider,
  Link,
} from "@material-ui/core";
import Help from "@material-ui/icons/Help";
import { Edit, Error } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { openInstructions, closeInstructions } from '../../redux/configSlice';
import Info from "@material-ui/icons/Info";

import MenuItem from "../Template/MuiMenuItem";
import InfoButton from '../Template/Info';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  instructionsText: {
    paddingLeft: '10px',
  },
  content: {
    fontSize: '1rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export default function Instructions(props) {
  const { instructionsOpen } = useSelector(({ config }) => config);
  const dispatch = useDispatch();

  const classes = useStyles();

  const { setAnchorEl } = props;

  const handleOpen = () => {
    dispatch(openInstructions());
  };

  const handleClose = () => {
    dispatch(closeInstructions());
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <Help /><span className={classes.instructionsText}>Instructions</span>
      </MenuItem>
      <Divider className={classes.divider} />
      <Dialog
        open={instructionsOpen}
        onClose={handleClose}
      >
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            Welcome to the <i>Buy &amp; Hold</i> Portfolio Calculator! This tool helps you calculate
            how much you should contribute or withdraw to/from each of the assets in your portfolio during
            a contribution, withdrawal, or rebalance of your portfolio. Below, you will find a brief
            overview of how the tool works and usage tips.
          </DialogContentText>
          <DialogContentText>
            Anxious to get started? This tool is built to be (hopefully) intuitive enough for you to skip
            these instructions. If you get stuck, you can come back here via
            <Info /> &rarr; "Instructions" at the top right.
          </DialogContentText>
          <DialogContentText variant='h6'>Table of contents</DialogContentText>
          <ul>
            <li><Link href='#strategy' color='secondary'>Algorithm overview</Link></li>
            <li><Link href='#basic-usage' color='secondary'>Basic usage</Link></li>
            <li><Link href='#other-activities' color='secondary'>Other activities</Link></li>
            <li><Link href='#tips' color='secondary'>Tips</Link></li>
          </ul>
          <DialogContentText variant='h6' id='strategy'>Algorithm overview</DialogContentText>
          <DialogContentText>
            Optimal contribution/withdrawal strategies here are defined as those that minimize the
            difference between an asset's balance and the target balance dictated by your desired
            asset mix. Contributions or withdrawals are made to/from assets in order of the distance
            assets are from their desired asset mix. Simultaneously, this algorithm seeks to ensure
            that assets do not drift further away from their desired mix. Therefore, after requesting
            a contribution or withdrawal, assets should be either about the same distance or closer to
            their desired asset mix (stocks/ETFs should be within one share of their initial asset
            mix).
          </DialogContentText>
          <DialogContentText variant='h6' id='basic-usage'>Basic usage:</DialogContentText>
          <ol>
            <li>
              To perform a rebalance of your portfolio, go to the "rebalance?" question and click the
              "YES" button. The option should be red if it is selected while unselected option should be gray. 
              If you instead would like to calculate a contribution or withdrawal without rebalancing your 
              portfolio, click the "NO" option.
            </li>
            <li>
              Choose whether you would like to contribute or withdraw money into/from your portfolio by
              selecting either the "CONTRIBUTE" or "WITHDRAW" button. The selection will be red.
              Note that you are also allowed to make a contribution or withdrawal during a portfolio
              rebalance. If you do not plan to make a contribution/withdrawal, you may use either
              option and set the "amount" to zero (you may do this when rebalancing).
            </li>
            <li>
              In the "amount" text box, set the amount you would like to contribute or withdraw
              from your portfolio (contribution/withdrawal determined by the button selected above).
              You may set this value to zero if you do not plan to contribute or withdraw any money.
            </li>
            <li>
              If you selected "CONTRIBUTE", you will also be given the option to choose
              between the "TARGETED" and "TRADITIONAL" strategy (red is the selected option).
              <ul>
                <li>
                  A "targeted" strategy tries to minimize the amount of capital gains tax if/when
                  you next rebalance your portfolio. It does this by contributing to assets that are
                  below your desired asset mix and possibly selling tax-exempt assets.
                </li>
                <li>
                  The "traditional" contribution strategy will contribute amounts to each of your
                  assets that is proportional to their asset mix. It also bases this decision on
                  your contribution history for a given desired asset mix.
                </li>
              </ul>
              Next to these strategy buttons is an info button <InfoButton /> that when clicked gives
              more detailed information about these strategies. It is up to you to decide which of
              these strategies and results best suit you, if any.
            </li>
            <li>
              Below the "amount" field if you selected "WITHDRAW" or the strategy buttons if you
              selected "CONTRIBUTE", there is a list of your assets. If this is the first time,
              there will be only one unnamed asset in the list. You can edit this asset by clicking
              the <Edit /> or <Error color='error' /> button. Note that the <Error color='error' />
              indicates that the asset is incomplete or has errors and must be edited before
              performing any calculations. After you click on the button, a dialog window will pop
              up titled "Edit Asset". All fields must be filled out:
              <ul>
                <li>
                  "Ticker symbol" is the ticker symbol of the asset. Note that if you include cash
                  in your portfolio, you must use the special "$cash" ticker symbol (which is
                  suggested when you click on the "Ticker symbol" field). You may put any name
                  you like in here but duplicates are not allowed.
                </li>
                <li>
                  The "Asset type" gives you the option to select "Mutual fund" for assets where
                  you do not need to buy individual shares but instead contribute a balance (as is
                  the case for mutual funds). If you need to buy individual units of an asset like
                  when buying shares of ETFs or stocks or buying individual bonds, you may select
                  the "ETF/Stock/Bond" option.
                  <ul>
                    <li>
                      If you selected the "Mutual fund" option, there will be a "Balance" field
                      where you should enter your present balance for the asset.
                    </li>
                    <li>
                      If you selected the "ETF/Stock/Bond" option, there will be a "Shares" field
                      and an "Order price/share" field. The "Shares" field is the number of (possibly
                      fractional) units you own of the asset. The "Order price/share" is the price
                      you would like to pay per unit for new units of this asset.
                    </li>
                  </ul>
                </li>
                <li>
                  The "Tax/fee status" is taxable if you have to pay taxes or fees when you sell this asset.
                  If the asset is tax (and fee) exempt, you may select tax-exempt. For targeted contributions,
                  tax-exempt assets may be sold and the resulting funds distributed to move your portfolio as
                  a whole closer to your desired asset mix.
                </li>
                <li>
                  "Desired asset mix" is the percentage of your portfolio that you would like
                  allocated to this asset. Across all assets, this percentage must add up to
                  100%. To help with this, a panel will drop down telling your the remaining
                  percentage you need to allocate.
                </li>
                <li>
                  When finished editing the asset, click the "CLOSE" button at the bottom right of the
                  box.
                </li>
              </ul>
            </li>
            <li>
              An asset may be deleted from the list by clicking on the <DeleteIcon /> button which will
              dropdown two buttons: a "Delete" button that will confirm deletion of the asset and a
              "Cancel" button that cancel deletion of the asset.
            </li>
            <li>
              Below the asset list, you will see a "red" button called "ADD ASSET". Clicking on this
              will add another asset to the above list and automatically open an editing window.
            </li>
            <li>
              To compute a contribution, withdrawal, or a rebalance, you finally click on the
              "CALCULATE" button below which will display the following results:
              <ul>
                <li>
                  For contributions/withdrawals, this will compute your contribution or withdrawal
                  (depending on whether "CONTRIBUTE" or "WITHDRAW" is selected above). The suggested
                  amount to buy or sell from each asset is displayed on the right side of each asset
                  in the above asset list. An overall status of the calculation is displayed to the
                  right above the asset list.
                </li>
                <li>
                  For a rebalance, this button will perform a rebalance and will report the
                  suggested actions for each asset in the same way as detailed above in the
                  above step.
                </li>
              </ul>
            </li>
            <li>
              After clicking the "CALCULATE" button and if you had selected the "TRADITIONAL"
              contribution strategy, a panel will appear from the bottom of the page with a
              "Last cycled" field and a "CYCLE" button. "Cycling" is needed for the traditional
              contribution strategy because calculations depend on your contribution history for
              a given desired asset mix. For more info on the justification for this button,
              click the info button <InfoButton /> next to the CYCLE button. Here
              is the usage of the CYCLE button:
              <ul>
                <li>
                  Do <b>not</b> click the CYCLE button until you have finished making your
                  contribution. That is, if you have not actually made the recommended contribution to
                  your real portfolio, you probably should not click the CYCLE button.
                </li>
                <li>
                  If you have actually made the recommeded contribution to your real portfolio, you
                  may then click the CYCLE button. You do not need to click it immediately and can
                  defer until your next visit. Just make sure you click the CYCLE button before you
                  calculate your next contribution.
                </li>
                <li>
                  If you forget whether you cycled or not, the "Last cycled" field tells you the
                  date and time of the last time you clicked the CYCLE button for this desired
                  asset mix. If you have never cycled for this desired asset mix before, it will
                  say "Never".
                </li>
                <li>
                  Note that if you modify your desired asset mix, the modified desired asset mix
                  has its own history. Your browser storage will still keep the history of your
                  previous desired asset mix.
                </li>
                <li>
                  If you accidentally click the CYCLE button before taking the last recommendation,
                  you can just accept the new recommendation. In the long run, it does not make
                  a major difference since the recommendations are cyclic. It is best to avoid
                  forgetting too often however since it will bias your contributions.
                </li>
                <li>
                  The data stored by the CYCLE button has no impact on any other calculation besides
                  traditional contributions.
                </li>
              </ul>
            </li>
          </ol>
          <DialogContentText id='other-activities' variant='h6'>Other activities</DialogContentText>
          <ul>
            <li>
              For convenience, this tool remembers your assets and some other information that you
              entered in the page when you return to the page. If you would like to delete this
              information, you can select <SettingsIcon /> &rarr; "Clear data".
            </li>
            <li>
              Since your data is persisted in your browser and is not stored on our server, you
              can export your data to transfer it to a differet browser/computer using
              <SettingsIcon /> &rarr; "Export data". This will save a file "portfolio-data.json" to
              your downloads folder. You may also want to do this if you want to keep your data before
              clearing your data from the browser as above.
            </li>
            <li>
              You can import data that you previously exported by <SettingsIcon /> &rarr;
              "Import data".
            </li>
          </ul>
          <DialogContentText id='tips' variant='h6'>Tips</DialogContentText>
          <ul>
            <li>
              Any numerical field may be treated as a calculator. You may compose mathematical
              expressions using (, ), +, -, *, /, along with numbers that will be evaluated once
              you leave the text field.
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}