import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import MenuItem from '../Template/MuiMenuItem';
import ConfirmClear from './ConfirmClear';

const useStyles = makeStyles(() => ({
  clearText: {
    paddingLeft: '10px',
  }
}));

export default function Clear(props) {
  const classes = useStyles();

  const { setAnchorEl } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <DeleteIcon />
        <span className={classes.clearText}>Clear data</span>
      </MenuItem>
      <ConfirmClear open={open} setOpen={setOpen} setAnchorEl={setAnchorEl} />
    </>
  );
}