import { useState } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import {
  setRemainder,
  setWithdrawn,
  setLastCalculation,
  isEdited,
  isNotEdited,
} from '../../redux/configSlice';
import { setResultData } from '../../redux/resultsSlice';

import errorWrapper from '../ErrorWrapper';
import { validateSubmission } from '../../utils/validation';
import { calculateContribution, calculateRebalance, calculateWithdrawal } from '../../utils/requests';
import { preprocessData, updateAssets } from './util';

const useStyles = makeStyles(() => ({
  button: {
    marginBottom: '5px',
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
  thinking: {
    color: 'rgb(230,230,230)',
  },
  buttonText: {
    paddingLeft: '10px',
    paddingRight: '30px',
  },
}));

function CalculateButton(props) {
  const {
    config = {},
    assets = {},
  } = useSelector((state) => state);

  const {
    rebalance,
    contribute,
    amount,
    strategy,
  } = config;
  const { assetData } = assets;
  const dispatch = useDispatch();

  const wrapDispatch = (f) => (args) => dispatch(f(args));

  const classes = useStyles();

  const [thinking, setThinking] = useState(false);

  const {
    setErrors,
  } = props;

  const keyedAssets = preprocessData(
    assetData,
    contribute,
    strategy,
  );

  const setEdited = (val) => val ? dispatch(isEdited()) : dispatch(isNotEdited());

  const onClick = async () => {
    if (thinking) {
      // Button was already clicked. Do not send another request.
      return;
    }

    // Set the loading icon.
    setThinking(true);
    dispatch(isEdited());

    // Validate data first.
    const errors = validateSubmission(assetData);
    if (errors.length > 0) {
      setThinking(false);
      return setErrors(errors);
    }

    const updateRebalance = (res) => updateAssets(
      res,
      contribute,
      strategy,
      assetData,
      wrapDispatch(setResultData),
      wrapDispatch(setRemainder),
      wrapDispatch(setWithdrawn),
      wrapDispatch(setLastCalculation),
      setEdited,
      setThinking,
      setErrors,
      'rebalance',
      'rebalance',
    );

    const updateContributeWithdraw = (res) => updateAssets(
      res,
      contribute,
      strategy,
      assetData,
      wrapDispatch(setResultData),
      wrapDispatch(setRemainder),
      wrapDispatch(setWithdrawn),
      wrapDispatch(setLastCalculation),
      setEdited,
      setThinking,
      setErrors,
      'contribution',
      'withdrawal',
    );

    if (rebalance) {
      await calculateRebalance(keyedAssets, contribute, amount, strategy, updateRebalance, setErrors);
    } else if (contribute) {
      await calculateContribution(keyedAssets, amount, strategy, updateContributeWithdraw, setErrors);
    } else {
      await calculateWithdrawal(keyedAssets, amount, updateContributeWithdraw, setErrors);
    }
    return setThinking(false);
  };

  return (
    <>
      <Button
        className={classes.button}
        variant='contained'
        color='secondary'
        onClick={onClick}
      >
        <div className={classes.buttonContent}>
          <CircularProgress
            size={25}
            thickness={4.5}
            className={classes.thinking}
            style={{ visibility: thinking ? 'visible' : 'hidden' }}
          />
          <span className={classes.buttonText}>Calculate</span>
        </div>
      </Button>
    </>
  );
}

export default errorWrapper(
  CalculateButton,
  'Calculation error',
  'Please fix the following issues and resubmit:',
);
