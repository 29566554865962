import { map, keyBy, reduce, assign, get } from 'lodash';

const now = () => new Date();

function isSubset(a, b) {
  const bSet = new Set(b);
  return a.reduce((truth, val) => truth && bSet.has(val), true);
}

function getActive(keyedAssetData) {
  return keyBy(
    map(keyedAssetData, (state, name) => ({ name, ...state }))
      .filter(({ desiredMix }) => desiredMix > 0),
    'name',
  );
}

function formatDefault(activeAssets) {
  return {
    assets: reduce(activeAssets, (all, { name, desiredMix }) =>
      assign(all, { [name]: { desiredMix, priorContribution: 0 } }), {})
  };
}

function isMatch(activeAssets, cacheRecord) {
  if (!isSubset(Object.keys(activeAssets), Object.keys(cacheRecord)) ||
    !isSubset(Object.keys(cacheRecord), Object.keys(activeAssets))) {
    return false;
  }

  return reduce(activeAssets, (truth, { desiredMix }, name) => {
    if (Math.abs(cacheRecord[name].desiredMix - desiredMix) < 1.0e-4) {
      return truth;
    }
    return false;
  }, true);
}

function matcher(activeAssets, cache) {
  let matched = undefined;
  let index = undefined;
  (cache || []).forEach((record, idx) => {
    if (isMatch(activeAssets, record.assets)) {
      matched = record;
      index = idx;
    }
  });

  return {
    matched,
    index,
  };
}

const getCache = () => JSON.parse(window.localStorage.getItem('traditionalCache')) || [];

const setCache = (cache) => window.localStorage.setItem('traditionalCache', JSON.stringify(cache));

const updateCache = (updates, cache, index) => {
  cache[index] = updates;
  setCache(cache);
}

const newCache = (cache) => {
  const cached = getCache();
  cached.push(cache);
  setCache(cached);
}

const getStaged = () => JSON.parse(window.localStorage.getItem('traditionalStaged')) || [];

const setStaged = (stage) => window.localStorage.setItem('traditionalStaged', JSON.stringify(stage));

const updateStaged = (updates, staged, index) => {
  staged[index] = updates;
  setStaged(staged);
}

const newStaged = (stage) => {
  const staged = getStaged();
  staged.push(stage);
  setStaged(staged);
}

const removeStaged = (staged, index) => {
  const cloned = JSON.parse(JSON.stringify(staged));
  cloned.splice(index, 1);
  setStaged(cloned);
}

export function recall(keyedAssetData) {
  // This runs before the contribution request.
  const cache = getCache();
  const activeAssets = getActive(keyedAssetData);

  const { matched } = matcher(activeAssets, cache);
  if (matched === undefined) {
    const formatted = formatDefault(activeAssets);
    // newCache(formatted);
    return formatted;
  }

  return matched;
}

export function stage(keyedAssetData) {
  // This runs after the contribution request.
  // This should be the keyedAssetData after adding deltaBalance and deltaShares.
  const activeAssets = getActive(keyedAssetData);

  const cache = getCache();
  const staged = getStaged();
  const { matched: matchedCache = {} } = matcher(activeAssets, cache);
  const { index: stageIndex } = matcher(activeAssets, staged);

  const newStage = reduce(
    activeAssets,
    (agg, {
      desiredMix,
      continuous,
      price,
      deltaBalance,
      deltaShares,
    }, name) => {
      const priorContribution = get(
        get(matchedCache, 'assets', {}),
        name,
        {}
      ).priorContribution || 0.0;
      if (continuous) {
        return assign(
          agg,
          {
            [name]: {
              desiredMix,
              priorContribution: priorContribution + deltaBalance
            }
          },
        );
      }
      return assign(
        agg,
        {
          [name]: {
            desiredMix,
            priorContribution: priorContribution + deltaShares * price
          }
        }
      );
    }, {});

  if (stageIndex !== undefined) {
    return updateStaged({ assets: newStage }, staged, stageIndex);
  }
  newStaged(assign({ assets: newStage }));
}

export function cycle(keyedAssetData) {
  // This can be used so that the user can cycle to the next contribution.
  const activeAssets = getActive(keyedAssetData);

  const cache = getCache();
  const staged = getStaged();
  const { index: cacheIndex } = matcher(activeAssets, cache);
  const { matched: matchedStaged, index: stagedIndex } = matcher(activeAssets, staged);

  const lastCycled = now();
  if (matchedStaged && cacheIndex !== undefined) {
    updateCache(assign(
      matchedStaged,
      { lastCycled }),
      cache,
      cacheIndex
    );
    removeStaged(staged, stagedIndex);
  } else if (matchedStaged) {
    newCache(assign(matchedStaged, { lastCycled }), cache);
    removeStaged(staged, stagedIndex);
  }
}

const preMerge = (keyedAssetData, recalledAssets) =>
  reduce(
    recalledAssets,
    (assets, { priorContribution }, asset) => {
      assets[asset].priorContribution = Math.round(100 * priorContribution);
      return assets;
    },
    keyedAssetData,
  );

const postMerge = (keyedAssetData, resultAssets) =>
  reduce(
    resultAssets,
    (assets, { deltaBalance, deltaShares }, asset) => {
      assets[asset].deltaBalance = deltaBalance;
      assets[asset].deltaShares = deltaShares;
      return assets;
    },
    keyedAssetData,
  );

const getCycle = (keyedAssetData) => {
  const { lastCycled } = recall(keyedAssetData);
  const staged = getStaged();
  const { index } = matcher(getActive(keyedAssetData), staged);

  return index !== undefined ? (lastCycled || 'Never') : undefined;
}

const lib = {
  recall,
  stage,
  cycle,
  preMerge,
  postMerge,
  getCycle,
}

export default lib;