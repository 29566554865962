import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { addAsset } from '../../redux/assetsSlice';
import { isEdited } from '../../redux/configSlice';

import EditAsset from '../AssetList/EditAsset';

const useStyles = makeStyles(() => ({
  button: {
    marginBottom: '5px',
  },
}))

export default function AddButton() {
  const { assetData } = useSelector(({ assets }) => assets);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOnClick = () => {
    dispatch(addAsset());
    setOpen(true);
    dispatch(isEdited());
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        onClick={handleOnClick}
      >
        Add asset
      </Button>
      <EditAsset
        open={open}
        onClose={handleClose}
        index={assetData.length - 1}
      />
    </>
  );
}
