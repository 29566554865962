import React from 'react';
import { TextField, InputAdornment, Divider, Typography, makeStyles } from '@material-ui/core';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import {
  setAmount,
  isContribute,
  isNotContribute,
  isEdited,
} from '../../redux/configSlice';

import Selection from './Selection';
import Strategy from './Strategy';
import Rebalance from './Rebalance';

import {
  onlyMath,
  roundOut,
  toZero,
} from '../../utils';
import { validateEval, validateField } from '../../utils/validation';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '20px',
    paddingRight: '19px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    width: '300px',
  },
  amount: {
    marginTop: '10px',
    width: '100%',
    maxWidth: '300px',
  },
  text: {
    color: 'black',
  },
});

export default function ContribWithdrawField() {
  const {
    amount,
    contribute,
  } = useSelector(({ config }) => config);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [err, setErr] = React.useState(validateEval(amount).message);

  const updateAmount = (amount) => {
    setErr(undefined);
    dispatch(setAmount({ amount }));
    dispatch(isEdited());
  };

  const rectify = (amount) => {
    if (amount < 0) {
      if (contribute) {
        dispatch(isNotContribute());
      } else {
        dispatch(isContribute());
      }
    }
    return Math.abs(amount);
  };

  const onFocus = (event) => event.target.select();

  const onChange = (event) => flow([
    onlyMath,
    updateAmount])(event.target.value);

  const onBlur = (event) => validateField(
    // Validation of expression evaluation.
    [validateEval],
    // Success callback computes the balance state update.
    flow([
      // eslint-disable-next-line no-eval
      eval,
      toZero,
      rectify,
      (val) => roundOut(val, 2),
      updateAmount,
    ]),
    // Error callback creates indicates the expression is invalid.
    (value, checked) => setErr(checked[0].message),
  )(event.target.value);

  const label = contribute ? 'contribution amount' : 'withdrawal amount';

  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>
        <Rebalance />
        <Divider style={{ marginBottom: '10px' }} />
        <Selection />
        <TextField
          error={Boolean(err)}
          helperText={err}
          className={classes.amount}
          variant='filled'
          label={label}
          value={amount}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <Typography className={classes.text}>
          <i>Set amount to 0 if you do not want to make a contribution or withdrawal.</i>
        </Typography>
        <Strategy />
      </div>
    </div>
  );
}
