import { AppBar, Typography, makeStyles } from "@material-ui/core";

import InfoMenu from "./InfoMenu";
import Settings from "./Settings";

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: '30px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}));

export default function HeadBanner(props) {
  const classes = useStyles();

  return (
    <AppBar position='relative' className={classes.root}>
      <div>
        <div>
          <Typography variant='h4'><i>{'Buy & Hold'}</i></Typography>
        </div>
        <div>
          <Typography variant='h5'>{'Portfolio Calculator'}</Typography>
        </div>
      </div>
      <div>
        <InfoMenu />
        <Settings />
      </div>
    </AppBar>
  )
}