import { Button, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(() => ({
  info: {
    fill: '#0000c0',
  },
  button: {
    padding: 0,
    minWidth: 0,
    minHeight: 0,
  },
}))

export default function Info(props) {
  const classes = useStyles();

  const { className, ...rest } = props;

  return (
    <Button className={`${classes.button} ${className || ''}`} {...rest}>
      <InfoIcon className={classes.info} />
    </Button>
  );
}