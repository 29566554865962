import React, { useEffect } from 'react';

export default function AdSense(props) {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  });

  const {
    dataAdClient,
    dataAdSlot,
    dataFullWidthResponsive = 'false',
  } = props;

  return (
    <ins
      className='adsbygoogle'
      style={{ display: 'block' }}
      data-ad-client={dataAdClient}
      data-ad-slot={dataAdSlot}
      data-ad-format='auto'
      data-full-width-responsive={dataFullWidthResponsive}
    />
  );
}

