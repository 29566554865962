import {
  Typography,
  ButtonGroup,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { isRebalance, isNotRebalance, isEdited } from "../../redux/configSlice";

const useStyles = makeStyles(() => ({
  div: {
    marginBottom: '15px',
  },
  root: {
    color: 'black',
    marginLeft: 0,
  },
  buttonGroup: {
    marginTop: '10px',
    marginRight: '10px',
    marginLeft: '15px',
  },
}))

const RebalanceButtons = (props) => {
  const classes = useStyles();

  const {
    rebalance,
    setColor,
    onClick,
  } = props;

  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Button
        variant='contained'
        color={setColor(rebalance)}
        onClick={onClick(true)}
      >
        Yes
      </Button>
      <Button
        variant='contained'
        color={setColor(!rebalance)}
        onClick={onClick(false)}
      >
        No
      </Button>
    </ButtonGroup>
  );
}

export default function Rebalance() {
  const { rebalance } = useSelector(({ config }) => config);
  const dispatch = useDispatch();

  const classes = useStyles();

  const setColor = (selected) => selected ? 'primary' : 'default';

  const onClick = (state) => () => {
    if (rebalance !== state) {
      dispatch(isEdited());
    }
    if (state) {
      dispatch(isRebalance());
    } else {
      dispatch(isNotRebalance());
    }
  };

  return (
    <div className={classes.div}>
      <Typography
        className={classes.root}
        variant='subtitle'>
        Are you rebalancing?
      </Typography>
      <RebalanceButtons
        rebalance={rebalance}
        setColor={setColor}
        onClick={onClick}
      />
    </div>
  );
}