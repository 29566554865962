import { post } from 'axios';

import { axiosError } from './errors';

function catcher(error, setErrors) {
  const message = axiosError(error);
  setErrors([{ state: false, message }]);
}

export function calculateContribution(keyedAssets, amount, strategy, updateAssets, setErrors) {
  return post(
    `${process.env.REACT_APP_API}/contribute`,
    {
      strategy,
      funds: keyedAssets,
      contribution: Math.round(100 * amount),
    },
  ).then(updateAssets)
    .catch((error) => catcher(error, setErrors));
}

export function calculateWithdrawal(keyedAssets, amount, updateAssets, setErrors) {
  return post(
    `${process.env.REACT_APP_API}/withdraw`,
    {
      funds: keyedAssets,
      withdraw: Math.round(100 * amount),
    },
  ).then(updateAssets)
    .catch((error) => catcher(error, setErrors));
}

export function calculateRebalance(keyedAssets, contribute, amount, strategy, updateAssets, setErrors) {
  return post(
    `${process.env.REACT_APP_API}/rebalance`,
    {
      strategy,
      funds: keyedAssets,
      ...(contribute ? { contribution: Math.round(100 * amount) } :
        { withdraw: Math.round(100 * amount) }),
    },
  ).then(updateAssets)
    .catch((error) => catcher(error, setErrors));
}
