import { configureStore } from "@reduxjs/toolkit";

import configSlice from "./configSlice";
import assetsSlice from "./assetsSlice";
import resultsSlice from "./resultsSlice";

export default configureStore({
  reducer: {
    config: configSlice,
    assets: assetsSlice,
    results: resultsSlice,
  },
});