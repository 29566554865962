import React from 'react';
import {
  Button,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';

import { deleteAsset } from '../../redux/assetsSlice';
import { isEdited } from '../../redux/configSlice';

import Menu from '../Template/MuiMenu';
import MenuItem from '../Template/MuiMenuItem';

const useStyles = makeStyles(() => ({
  popover: {
    // padding: '10px',
  },
  text: {
    paddingBottom: '10px',
  },
}));

export default function Delete(props) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { index } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = () => {
    dispatch(deleteAsset({ index }));
    dispatch(isEdited());
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <DeleteIcon />
      </Button>
      <Menu
        classes={{ paper: classes.popover }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleConfirm}>
          <Typography color='error'>Delete</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose} autoFocus>Cancel</MenuItem>
      </Menu>
    </>
  );
}
