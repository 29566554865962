import { ButtonGroup, Button, Typography, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { isContribute, isEdited, isNotContribute } from '../../redux/configSlice';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
    marginRight: '10px',
  },
  text: {
    color: 'black',
  }
}))

export default function Selection() {
  const { contribute } = useSelector(({ config }) => config);
  const dispatch = useDispatch();

  const classes = useStyles();

  const setColor = (selected) => selected ? 'primary' : 'default';

  const onClick = (state) => () => {
    if (contribute !== state) {
      dispatch(isEdited());
    }
    if (state) {
      dispatch(isContribute());
    } else {
      dispatch(isNotContribute());
    }
  };

  return (
    <>
      <Typography className={classes.text} variant='subtitle'>
        How much would you like to contribute or withdraw?
      </Typography>
      <ButtonGroup className={classes.root}>
        <Button
          variant='contained'
          color={setColor(contribute)}
          onClick={onClick(true)}
        >
          Contribute
        </Button>
        <Button
          variant='contained'
          color={setColor(!contribute)}
          onClick={onClick(false)}
        >
          Withdraw
        </Button>
      </ButtonGroup>
    </>
  );
}