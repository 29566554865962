import React from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';

import { updateAsset } from '../../../redux/assetsSlice';
import { isEdited } from '../../../redux/configSlice';

import { validateName } from '../../../utils/validation';

const useStyles = makeStyles(() => ({
  root: {
    width: '150px',
  },
}));


export default function Ticker(props) {
  const { assetData } = useSelector(({ assets }) => assets);
  const dispatch = useDispatch();

  const { index } = props;

  const classes = useStyles();

  const { name = '' } = assetData[index];

  const [err, setErr] = React.useState(validateName(name, assetData, index).message);

  const updateName = (event, values) => {
    const newName = values || event.target.value;
    if (newName === '$cash') {
      dispatch(updateAsset({
        updates: {
          name: newName,
          continuous: true,
          taxable: false,
        }, index
      }));
    } else {
      dispatch(updateAsset({ updates: { name: newName }, index }));
    }
    dispatch(isEdited());
    setErr(validateName(newName, assetData, index).message);
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Autocomplete
        className={classes.root}
        value={name}
        options={['$cash']}
        getOptionLabel={(option) => option}
        getOptionSelected={() => true}
        onChange={updateName}
        renderInput={(params) =>
          <TextField
            error={Boolean(err)}
            helperText={err}
            {...params}
            variant='filled'
            label='Ticker symbol'
            onChange={updateName}
            value={name}
          />}
      />
    </Grid>
  );
}
