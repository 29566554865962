import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  box: {
    marginLeft: '20px',
    marginRight: '20px',
  },
}));

export default function Ticker(props) {
  const classes = useStyles();

  const { name } = props;

  const color = name && name.length > 0 ? 'black' : '#f44336';

  return (
    <Box className={classes.box}>
      <Typography
        color='textSecondary'
        variant='h5'
        style={{ color }}
      >
        {name || '???'}
      </Typography>
    </Box>
  );
}
