import React from 'react';
import { Grid, Box, TextField, InputAdornment, makeStyles } from '@material-ui/core';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { updateAsset } from '../../../redux/assetsSlice';
import { isEdited } from '../../../redux/configSlice';

import {
  onlyMath,
  roundOut,
  bound,
  toZero,
  formatMoney,
} from '../../../utils';
import { validateEval, validateField } from '../../../utils/validation';

const useStyles = makeStyles(() => ({
  root: {
    width: '170px',
  },
}));

export default function Price(props) {
  const { assetData } = useSelector(({ assets }) => assets);
  const dispatch = useDispatch();

  const { index } = props;

  const classes = useStyles();

  const { price = 0.01, continuous = true } = assetData[index];

  const [err, setErr] = React.useState(validateEval(price).message);

  const updatePrice = (price) => {
    setErr(undefined);
    dispatch(updateAsset({ updates: { price }, index }));
    dispatch(isEdited());
  };

  const onFocus = (event) => event.target.select();

  const onChange = (event) => flow([
    onlyMath,
    updatePrice])(event.target.value);

  const onBlur = (event) => validateField(
    // Validation of expression evaluation.
    [validateEval],
    // Success callback computes the balance state update.
    flow([
      // eslint-disable-next-line no-eval
      eval,
      toZero,
      (val) => roundOut(val, 2),
      (val) => bound(val, { lb: 0.01 }),
      updatePrice,
    ]),
    // Error callback creates indicates the expression is invalid.
    (value, checked) => setErr(checked[0].message),
  )(event.target.value);

  return (
    <Box
      component={Grid}
      item
      xs={12}
      sm={6}
      md={4}
      {...(continuous && { display: 'none' })}
    >
      <TextField
        error={Boolean(err)}
        helperText={err}
        className={classes.root}
        variant='filled'
        label='Order price/unit'
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        value={formatMoney(price)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </Box>
  );
}
