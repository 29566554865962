import { useState } from 'react';
import { keyBy } from 'lodash';
import {
  Drawer,
  Button,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoButton from '../Template/Info';
import { useSelector, useDispatch } from 'react-redux';

import { isEdited } from '../../redux/configSlice';

import traditionalLib from '../../utils/traditionalLib';
import { preprocessData } from './util';

const useStyles = makeStyles(() => ({
  timestamp: {
    margin: 'auto',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  actions: {
    margin: 'auto',
    alignItems: 'center',
  },
  button: {
    width: '150px',
    paddingLeft: '20px',
    paddingRight: '30px',
    marginBottom: '20px',
  },
  refresh: {
    marginRight: '5px',
  },
  infoButton: {
    marginLeft: '5px',
    marginBottom: '20px',
  },
  content: {
    fontSize: '1rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))

function Info(props) {
  const classes = useStyles();

  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>When and why do we need to "cycle" traditional contributions?</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText variant='h6'>When do we cycle?</DialogContentText>
        <ul>
          <li>
            Do <b>not</b> click the CYCLE button until you have finished making your
            contribution. That is, if you have not actually made the recommended contribution to
            your real portfolio, you probably should not click the CYCLE button.
          </li>
          <li>
            If you have actually made the recommeded contribution to your real portfolio, you
            may then click the CYCLE button. You do not need to click it immediately and can
            defer until your next visit. Just make sure you click the CYCLE button before you
            calculate your next contribution.
          </li>
          <li>
            If you forget whether you cycled or not, the "Last cycled" field tells you the
            date and time of the last time you clicked the CYCLE button for this desired
            asset mix. If you have never cycled for this desired asset mix before, it will
            say "Never".
          </li>
          <li>
            Note that if you modify your desired asset mix, the modified desired asset mix
            has its own history. Your browser storage will still keep the history of your
            previous desired asset mix.
          </li>
          <li>
            If you accidentally click the CYCLE button before taking the last recommendation,
            you can just accept the new recommendation. In the long run, it does not make
            a major difference since the recommendations are cyclic. It is best to avoid
            forgetting too often however since it will bias your contributions.
          </li>
          <li>
            The data stored by the CYCLE button has no impact on any other calculation besides
            traditional contributions.
          </li>
        </ul>
        <DialogContentText variant='h6'>Why do we cycle?</DialogContentText>
        <DialogContentText>
          For the "traditional" contribution strategy only, this CYCLE button is used to keep
          track of the contribution history for a given desired asset mix. This is used in the
          calculation to ensure that the rounding involved in attempting to make a contribution
          proportional to the desired asset mix does not bias your contributions towards certain
          assets. It is easiest to understand the issue through an example:
        </DialogContentText>
        <DialogContentText>
          Suppose you entered the following relevant information for your portfolio:
        </DialogContentText>
        <ul>
          <li><b>Asset A</b> desired mix: 50%, not a mutual fund, order price: $500</li>
          <li><b>Asset B</b> desired mix: 50%, mutual fund.</li>
        </ul>
        <DialogContentText>
          You then would like to contribute $500 to your portfolio using a traditional
          contribution. If we calculate the contribution proportionately, then the
          contribution should be evenly split: 50% of $500 is $250 so we should
          contribute $250 each to assets A and B.
        </DialogContentText>
        <DialogContentText>
          However, asset A is a stock with an order price of $500 and we cannot purchase
          a 1/2 share of asset A ($250/$500 = 1/2). One solution might be to resort to
          rounding:
        </DialogContentText>
        <ul>
          <li><b>Round up:</b> buy $500 of A (1 share) and $0 of B</li>
          <li><b>Round down:</b> buy $0 of A (0 shares) and $500 of B</li>
        </ul>
        <DialogContentText>
          The problem with rounding alone is that choosing one of these strategies is
          going to bias your contributions to being either 100% asset A or 100% asset B.
        </DialogContentText>
        <DialogContentText>
          This is where the contribution history comes in. Instead of always rounding up
          or down, we can instead choose to round up or down based on the contribution
          history for the given desired mix. So if we round up the first time (1 share of
          asset A and $0 of asset B), we can store that and then notice next time that we
          have contributed too much to asset A (it is 100% of the contributions so far
          instead of the desired 50%) and too little to asset B (it is 0% of the contributions
          instead of the desired 50%). Next time we contribute, we would instead buy 0 shares
          of asset A; and so on. This forms a contribution cycle like the following:
        </DialogContentText>
        <ol>
          <li>Asset A: $500, Asset B: $0</li>
          <li>Asset A: $0, Asset B: $500</li>
          <li>Asset A: $500, Asset B: $0</li>
          <li>Asset A: $0, Asset B: $500</li>
          <li>...</li>
        </ol>
        <DialogContentText>
          This illustrates the reason why we need to track contribution history. Since
          contribution history per desired asset mix configuration is difficult for the user
          to track, this is done internally by the CYCLE button where, when clicked, it will
          assume you have contributed the amounts recommended by the tool from the previous
          calculation.
        </DialogContentText>
        <DialogContentText>
          Using the above example, if I set up a traditional contribution and click the CALCULATE
          button, it will compute step 1. If I press the CALCULATE button a second time, it will
          return the same result from step 1. If I click the CYCLE button and then click
          CALCULATE, it will return the result from step 2. So the purpose of the CYCLE button
          is to store a contribution and move to the next "step" in the cycle.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Cycle() {
  const {
    config = {},
    assets = {},
  } = useSelector((state) => state);
  const {
    contribute,
    strategy,
    rebalance,
  } = config;
  const { assetData } = assets;
  const dispatch = useDispatch();

  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [updated, setUpdated] = useState(undefined);
  const [openInfo, setOpenInfo] = useState(false);

  const lastCycled = (
    !rebalance &&
    contribute &&
    strategy === 'traditional' &&
    traditionalLib.getCycle(preprocessData(assetData))
  );

  const onClick = () => {
    const keyedAssetData = keyBy(assetData, 'name');
    traditionalLib.cycle(keyedAssetData);
    setUpdated(new Date());
    dispatch(isEdited());
  }

  return (
    <Drawer
      anchor='bottom'
      open={Boolean(lastCycled)}
      variant='persistent'
    >
      <div className={classes.timestamp}>
        <Typography variant='subtitle'>
          <b>Last cycled:</b> {Date.parse(lastCycled) ?
            new Date(lastCycled).toLocaleString() : lastCycled}
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color='primary'
          variant='contained'
          onClick={onClick}
        >
          <RefreshIcon className={classes.refresh} /> Cycle
        </Button>
        <InfoButton
          className={classes.infoButton}
          onClick={() => setOpenInfo(true)}
        />
        <Info open={openInfo} setOpen={setOpenInfo} />
      </div>
    </Drawer>
  );
}
