import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import BugReportIcon from '@material-ui/icons/BugReport';

import MenuItem from "../Template/MuiMenuItem";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  supportText: {
    paddingLeft: '10px',
  }
}));

export default function Support(props) {
  const classes = useStyles();

  const { setAnchorEl } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  }

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <BugReportIcon /><span className={classes.supportText}>Support/Bug reports</span>
      </MenuItem>
      <Divider className={classes.divider} />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Support/Bug reports</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>
              {'If you have any comments, suggestions, bugs to report, etc. please send an email to '}
            </span>
            <a href='mailto: support@buythenhold.com'>support@buythenhold.com</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}