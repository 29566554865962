import { useState } from 'react';
import { Button, makeStyles } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';

import Menu from '../Template/MuiMenu';
import Clear from './Clear';
import Export from './Export';
import Import from './Import';

const useStyles = makeStyles(() => ({
  settingsButton: {
    minWidth: '45px',
    maxWidth: '45x',
    marginLeft: '5px',
    marginRight: '15px',
  },
  settingsIcon: {
    fontSize: '35px',
    fill: 'rgb(205, 205, 205)',
  },
}));

export default function Settings() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Button className={classes.settingsButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
        <SettingsIcon className={classes.settingsIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Export setAnchorEl={setAnchorEl} />
        <Import setAnchorEl={setAnchorEl} />
        <Clear setAnchorEl={setAnchorEl} />
      </Menu>
    </>
  )
}