import { ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AddButton from './AddButton';
import CalculateButton from './CalculateButton';
import ReportButton from './Report';

const useStyles = makeStyles(() => ({
  div: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonGroup: {
    margin: 'auto',
    width: '100%',
    maxWidth: '400px',
    paddingTop: '15px',
  },
}));

export default function Controls() {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <ButtonGroup orientation='vertical' className={classes.buttonGroup}>
        <AddButton />
        <CalculateButton />
        <ReportButton />
      </ButtonGroup>
    </div>
  );
}
