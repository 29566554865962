import React from 'react';
import { Button } from '@material-ui/core';
import { Edit, Error } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { validateAsset } from '../../utils/validation';

import EditAsset from './EditAsset';

const getIcon = (valid, hover) => {
  if (valid && hover) {
    return <Edit />;
  }
  if (valid && !hover) {
    return <Edit />;
  }
  if (!valid && hover) {
    return <Edit color='error' />;
  }
  if (!valid && !hover) {
    return <Error color='error' />;
  }
}

export default function EditButton(props) {
  const { assetData } = useSelector(({ assets }) => assets);

  const { index } = props;

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const valid = validateAsset(assetData, index);

  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const onMouseOver = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <>
      <Button
        onClick={handleOnClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {getIcon(valid, hover)}
      </Button>
      <EditAsset
        open={open}
        onClose={handleClose}
        index={index}
      />
    </>
  );
}
