import {
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { updateAsset } from '../../../redux/assetsSlice';
import { isEdited } from '../../../redux/configSlice';

export default function Continuous(props) {
  const { assetData } = useSelector(({ assets }) => assets);
  const dispatch = useDispatch();

  const { index } = props;

  const { name, continuous = true } = assetData[index];

  const updateContinuous = () => {
    dispatch(updateAsset({ updates: { continuous: !continuous }, index }));
    dispatch(isEdited());
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Asset type</FormLabel>
        <RadioGroup
          value={continuous}
          onChange={updateContinuous}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label='Mutual fund'
            disabled={name === '$cash'}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label='ETF/Stock/Bond'
            disabled={name === '$cash'}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
