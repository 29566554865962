import React, { useEffect } from 'react';
import {
  makeStyles,
  Grid,
  ThemeProvider,
} from '@material-ui/core';
import ReactGA from 'react-ga';

import theme from './theme';

import HeadBanner from './components/HeadBanner';
import Footer from './components/Footer';
import ContribWithdrawField from './components/ContribWithdrawField';
import AssetList from './components/AssetList';
import Controls from './components/Controls';
import Cycle from './components/Controls/Cycle';
import AdSense from './components/AdSense';
import axios from 'axios';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-200763466-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: 'rgb(245, 245, 245)'
  },
  header: {
    width: '100%',
  },
  main: {
    // backgroundColor: '#282c34',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    color: 'white',
    width: '100%',
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '50px',
  },
}));

function App() {
  const classes = useStyles();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}`);
  })

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <header className={classes.header}>
          <HeadBanner />
        </header>
        <main className={classes.main}>
          <Grid container spacing={3}>
            <ContribWithdrawField />
          </Grid>
          <AssetList />
          <Controls />
          <div>
            <AdSense 
              dataAdClient='ca-pub-5122168012293109'
              dataAdSlot='8697990395'
              dataFullWidthResponsive='true'
            />
          </div>
        </main>
        <Footer />
        <Cycle />
      </div>
    </ThemeProvider>
  );
}

export default App;
