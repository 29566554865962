import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import MenuItem from "../Template/MuiMenuItem";

const useStyles = makeStyles(() => ({
  aboutText: {
    paddingLeft: '10px',
  }
}));

export default function About(props) {
  const classes = useStyles();

  const { setAnchorEl } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  }

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <InfoIcon /><span className={classes.aboutText}>About</span>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>About</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This tool was created as an aid to passive investors that seek to maintain a portfolio
            where assets are bought/sold to reach a desired asset mix. Of potential key interest is
            the "targeted" contribution strategy that gives the user the option of targeting
            contributions towards assets that are below their desired asset mix. Users should keep in
            mind when using this tool that this is not a substitute for professional financial advice
            and one should evaluate the suggestions accordingly.
          </DialogContentText>
          <DialogContentText>
            This tool does not store any of your information server-side and instead uses
            client-side browser storage.
            {/* <b>To fund this tool and provide it to you for free,
              (non-obnoxious) advertising is used. Please disable your ad-blocker to help keep this
              tool alive.</b> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}