import { Box, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { roundOut, formatMoney } from '../../utils';

const useStyles = makeStyles(() => ({
  buyText: {
    color: 'rgb(0,200,0)',
  },
  sellText: {
    color: 'rgb(200,0,0)',
  },
  passText: {
    color: 'rgb(0,0,0)',
  },
}));

export default function Result(props) {
  const { edited } = useSelector(({ config }) => config);

  const {
    name,
    continuous,
    deltaBalance,
    deltaShares,
    price,
  } = props;

  const classes = useStyles();

  const chooseClass = (val) => {
    if (val === undefined) {
      return classes.passText;
    }
    if (val > 0.0001) {
      return classes.buyText;
    }
    if (val < -0.0001) {
      return classes.sellText;
    }
    return classes.passText;
  }

  let textClass = chooseClass(deltaBalance);
  let text = '';
  if (name === '$cash') {
    textClass = chooseClass(deltaBalance);
    if (deltaBalance !== undefined) {
      if (deltaBalance > 0.0001) {
        text = `Deposit $${formatMoney(roundOut(deltaBalance, 2))}.`;
      } else if (deltaBalance < -0.0001) {
        text = `Withdraw $${formatMoney(roundOut(-deltaBalance, 2))}.`;
      } else {
        text = 'Do nothing.';
      }
    }
  } else if (continuous) {
    textClass = chooseClass(deltaBalance);
    if (deltaBalance !== undefined) {
      if (deltaBalance > 0.0001) {
        text = `Buy $${formatMoney(roundOut(deltaBalance, 2))}.`;
      } else if (deltaBalance < -0.0001) {
        text = `Sell $${formatMoney(roundOut(-deltaBalance, 2))}.`;
      } else {
        text = 'Do nothing.';
      }
    }
  } else {
    textClass = chooseClass(deltaShares);
    if (deltaShares !== undefined) {
      if (deltaShares > 0) {
        const s = deltaShares > 1.5 ? 's' : '';
        text = `Buy ${roundOut(deltaShares, 0)} unit${s} at $${formatMoney(price)}.`;
      } else if (deltaShares < 0) {
        const s = deltaShares < -1.5 ? 's' : '';
        text = `Sell ${roundOut(-deltaShares, 0)} unit${s} at $${formatMoney(price)}.`;
      } else {
        text = 'Do nothing.';
      }
    }
  }

  return (
    <Box style={{ width: '100%' }} alignContent='center'>
      <Typography
        className={textClass}
        align='right'
        variant='subtitle1'
        style={{ textDecoration: edited ? 'line-through' : 'none' }}
      >
        {text}
      </Typography>
    </Box>
  );
}