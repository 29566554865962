const axiosErrorTypes = {
  '500': 'Internal server error.',
  '429': 'Request rate limit exceeded. Only 50/hour or 200/day requests are allowed.',
};

export function axiosError(error) {
  console.log(error);
  if (error.response) {
    const status = error.response.status;
    return `Status ${status}: ${axiosErrorTypes[status] || 'Unknown error.'}`;
  }
  if (error.request) {
    console.log(error.request);
    return 'Request was not received. Please check your internet connection.';
  }
  return error.message;
}