import { useState } from 'react';
import { makeStyles } from "@material-ui/core";

import Privacy from "./Policies/Privacy";
import Terms from './Policies/Terms';

const useStyles = makeStyles(() => ({
  root: {
    color: 'black',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: 'auto',
  },
  policies: {
    textAlign: 'center'
  }
}));

export default function Footer() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={classes.root}>
      <div className={classes.policies} anchorEl={anchorEl}>
        <Terms setAnchorEl={setAnchorEl} />
        {'\u00A0'}{'\u00A0'}{'\u00A0'}
        <Privacy setAnchorEl={setAnchorEl} />
      </div>
    </div>
  )
}