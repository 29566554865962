import { every, map, unique, flatMap, round } from 'lodash';

export function validateEval(value) {
  try {
    eval(value);
    return { state: true };
  } catch (err) {
    if (err instanceof SyntaxError) {
      return { state: false, message: 'Invalid expression.' };
    }
  }
}

export function validateName(name, assetData, index) {
  if (name === '') {
    return { state: true };
  }
  const allNames = map(assetData.filter(
    (asset, idx) => idx !== index
  ), 'name');
  const state = !allNames.includes(name);
  return {
    state,
    message: state ? undefined : 'Symbol already used.'
  };
}

function validateLength(name, minLength) {
  if (name !== undefined && name.length >= minLength) {
    return { state: true };
  }
  return { state: false, message: `Name must have minimum length ${minLength}.` };
}

export function validateField(conditions, callback, err) {
  return function (value) {
    const checked = conditions.map((cond) => cond(value));
    const state = every(map(checked, 'state'));
    if (state && callback !== undefined) {
      return callback(value);
    }
    if (!state && err !== undefined) {
      return err(value, checked.filter(({ state: s }) => !s));
    }
    return state;
  }
}

export function validateNames(assetData) {
  const allNames = map(assetData, 'name');
  return allNames.length === unique(allNames).length;
}

function validateMath(field, fieldName = 'Field') {
  if (field === undefined) {
    return { state: false, message: `${fieldName} cannot be undefined.` };
  }
  return validateEval(field);
}

export function validateAsset(assetData, index) {
  const asset = assetData[index];
  return every([
    validateLength(asset.name, 1).state,
    validateName(asset.name, assetData, index).state,
    validateMath(asset.balance).state,
    validateMath(asset.shares).state,
    validateMath(asset.price).state,
    validateMath(asset.desiredMix).state,
  ]);
}

function validateMixTotal(assetData) {
  const total = assetData.reduce((sum, { desiredMix = 0.0 }) => sum + desiredMix, 0.0);
  const missing = 100.0 - total;
  if (Math.abs(missing) > 0.0001) {
    return {
      state: false,
      message: `Desired asset mix does not add up to 100%. ${round(missing, 5)}% must be allocated.`
    };
  }
  return { state: true };
}

function validateDiscreteAssetCount(assetData, maxCount = 100) {
  const sum = assetData.reduce((total, { continuous }) => total + (continuous ? 0 : 1), 0);
  if (sum > maxCount) {
    return {
      state: false,
      message: `Results are only reliable for ${maxCount} non-mutual fund assets (e.g. stocks and ETFs).`,
    };
  }
  return { state: true };
}

export function validateSubmission(assetData) {
  const ifExists = (f) => (val) => val !== undefined ? f(val) : { state: true };

  const tests = [
    ({ name }) => validateLength(name, 1),
    ({ name }, index) => validateName(name, assetData, index),
    ({ balance }) => ifExists(validateMath)(balance),
    ({ shares }) => ifExists(validateMath)(shares),
    ({ price }) => ifExists(validateMath)(price),
    ({ desiredMix = 0.0 }) => validateMath(desiredMix),
  ];

  const errors = flatMap(assetData, (asset, index) => {
    return tests.map((test) => {
      const { state, message } = test(asset, index);
      if (!state && message) {
        return {
          state,
          message: `${asset.name}: ${message}`,
        };
      }
      return { state, message };
    });
  });

  errors.push(validateMixTotal(assetData));
  errors.push(validateDiscreteAssetCount(assetData));

  return errors.filter(({ state }) => !state);
}
