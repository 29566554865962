import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  content: {
    fontSize: '1rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export default function Privacy(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { setAnchorEl } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Link href='#' onClick={handleOpen}>Privacy policy</Link>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Buy &amp; Hold Portfolio Calculator Privacy policy</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            This Privacy Policy describes how your personal information is collected, used, and shared when
            you visit https://buythenhold.com (the “Site”).
          </DialogContentText>
          <DialogContentText variant='subtitle'>PERSONAL INFORMATION WE COLLECT</DialogContentText>
          <DialogContentText>
            When you visit the Site, we automatically collect certain information about your device, including
            information about your web browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we collect information about the
            individual web pages or products that you view, what websites or search terms referred you to the
            Site, and information about how you interact with the Site. We refer to this automatically-collected
            information as “Device Information.”
          </DialogContentText>
          <DialogContentText>
            We collect Device Information using the following technologies:
          </DialogContentText>
          <ul>
            <li>
              “Cookies” are data files that are placed on your device or computer and often include an
              anonymous unique identifier. For more information about cookies, and how to disable cookies,
              visit <Link href='http://www.allaboutcookies.org'>http://www.allaboutcookies.org</Link>.
            </li>
            <li>
              “Log files” track actions occurring on the Site, and collect data including your IP address,
              browser type, Internet service provider, referring/exit pages, and date/time stamps.
            </li>
            <li>
              Web beacons,” “tags,” and “pixels” are electronic files used to record information about how
              you browse the Site.
            </li>
          </ul>
          <DialogContentText>
            When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
            Information.
          </DialogContentText>
          <DialogContentText variant='subtitle'>HOW DO WE USE YOUR PERSONAL INFORMATION?</DialogContentText>
          <DialogContentText>
            We use the Device Information that we collect to help us screen for potential risk and fraud
            (in particular, your IP address), and more generally to improve and optimize our Site (for example,
            by generating analytics about how our customers browse and interact with the Site, and to assess
            the success of our marketing and advertising campaigns).
          </DialogContentText>
          <DialogContentText>
            We share your Personal Information with third parties to help us use your Personal Information,
            as described above. For example, we use Google Analytics to help us understand how our customers
            use the Site--you can read more about how Google uses your Personal Information here:&nbsp;
            <Link href='https://www.google.com/intl/en/policies/privacy/'>https://www.google.com/intl/en/policies/privacy/</Link>.
            You can also opt-out of Google Analytics here:&nbsp;
            <Link href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</Link>.
          </DialogContentText>
          <DialogContentText>
            Finally, we may also share your Personal Information to comply with applicable laws and
            regulations, to respond to a subpoena, search warrant or other lawful request for information
            we receive, or to otherwise protect our rights.
          </DialogContentText>
          <DialogContentText variant='subtitle'>DO NOT TRACK</DialogContentText>
          <DialogContentText>
            Please note that we do not alter our Site’s data collection and use practices when we see a
            Do Not Track signal from your browser.
          </DialogContentText>
          <DialogContentText variant='subtitle'>YOUR RIGHTS</DialogContentText>
          <DialogContentText>
            If you are a European resident, you have the right to access personal information we hold about
            you and to ask that your personal information be corrected, updated, or deleted. If you would
            like to exercise this right, please contact us through the contact information below.
          </DialogContentText>
          <DialogContentText>
            Additionally, if you are a European resident we note that we are processing your information in
            order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above.  Additionally,
            please note that your information will be transferred outside of Europe, including to Canada and
            the United States.
          </DialogContentText>
          <DialogContentText variant='subtitle'>DATA RETENTION</DialogContentText>
          <DialogContentText>
            Apart from information contained in ephemeral logs, none of your Personal Information is otherwise
            retained at this time.
          </DialogContentText>
          <DialogContentText variant='subtitle'>CHANGES</DialogContentText>
          <DialogContentText>
            We may update this privacy policy from time to time in order to reflect, for example, changes to
            our practices or for other operational, legal or regulatory reasons.
          </DialogContentText>
          <DialogContentText variant='subtitle'>CONTACT US</DialogContentText>
          <DialogContentText>
            For more information about our privacy practices, if you have questions, or if you would like to
            make a complaint, please contact us by e-mail at support@buythenhold.com.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}