import React from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { updateAsset } from '../../../redux/assetsSlice';
import { isEdited } from '../../../redux/configSlice';

import {
  onlyMath,
  roundOut,
  bound,
  toZero,
} from '../../../utils';
import { validateEval, validateField } from '../../../utils/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '170px',
  },
  popover: {
    pointerEvents: 'none',
    padding: theme.spacing(1),
  },
}));

export default function DesiredMix(props) {
  const { assetData } = useSelector(({ assets }) => assets);
  const dispatch = useDispatch();

  const { index } = props;

  const classes = useStyles();

  const { desiredMix = 0.0 } = assetData[index];
  const upperBound = 100.0 - assetData.reduce(
    (acc, { desiredMix: curr }) => {
      try {
        // eslint-disable-next-line no-eval
        return acc + (Number(eval(curr)) || 0.0);
      } catch {
        return acc;
      }
    },
    0.0,
    assetData);

  const [err, setErr] = React.useState(validateEval(desiredMix).message);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const updateDesiredMix = (desiredMix) => {
    setErr(undefined);
    dispatch(updateAsset({ updates: { desiredMix }, index }));
    dispatch(isEdited());
  };

  const boundPercent = (desiredMix) =>
    bound(
      desiredMix,
      {
        lb: 0.0,
        ub: upperBound + Number(desiredMix),
      },
    );

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const onFocus = (event) => {
    handlePopoverOpen(event);
    event.target.select()
  };

  const onChange = (event) => flow([
    onlyMath,
    updateDesiredMix])(event.target.value);

  const onBlur = (event) => validateField(
    // Validation of expression evaluation.
    [validateEval],
    // Success callback computes the balance state update.
    flow([
      // eslint-disable-next-line no-eval
      eval,
      toZero,
      (val) => roundOut(val, 2),
      boundPercent,
      updateDesiredMix,
      handlePopoverClose,
    ]),
    // Error callback creates indicates the expression is invalid.
    (value, checked) => setErr(checked[0].message),
  )(event.target.value);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <TextField
        error={Boolean(err)}
        helperText={err}
        className={classes.root}
        variant='filled'
        label='Desired asset mix'
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        value={desiredMix}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        aria-owns={Boolean(anchorEl) ? 'remainder-popover' : undefined}
        aria-haspopup="true"
      />
      <Popover
        id='remainder-popover'
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Typography variant='subtitle1'>
          Remaining: {roundOut(upperBound, 7)}%
        </Typography>
      </Popover>
    </Grid>
  );
}
