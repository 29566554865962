import { forEach } from 'lodash';
import { Divider, makeStyles } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/Publish';

import { readUpload } from '../../utils/io';
import MenuItem from '../Template/MuiMenuItem';
import errorWrapper from '../ErrorWrapper';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  importText: {
    paddingLeft: '10px',
  }
}));

function Import(props) {
  const classes = useStyles();

  const { setAnchorEl, setErrors } = props;

  const handleClick = async () => {
    const file = document.getElementById('fileImport').files[0];
    if (file) {
      try {
        const content = await readUpload(file);
        forEach(content, (value, key) => {
          if (typeof value === 'object') {
            window.localStorage.setItem(key, JSON.stringify(value));
          } else {
            window.localStorage.setItem(key, value);
          }
        });
        window.location.reload();
      } catch {
        setErrors([{
          state: false,
          message: 'Malformed JSON data. File is invalid.',
        }]);
      }
    }
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem component='label'>
        <UploadIcon />
        <input
          type='file'
          id='fileImport'
          onChange={handleClick}
          hidden
        />
        <span className={classes.importText}>Import data</span>
      </MenuItem>
      <Divider className={classes.divider} />
    </>
  );
}

export default errorWrapper(
  Import,
  'File import error.',
  'The following error(s) occurred while trying to import the file.'
);