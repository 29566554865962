import { createSlice } from '@reduxjs/toolkit';

export const resultsSlice = createSlice({
  name: 'results',

  initialState: {
    resultData: [],
  },

  reducers: {
    setResultData: (state, { payload = {} }) => {
      state.resultData = payload.resultData;
    },
    clearResultData: (state) => {
      state.resultData = [];
    }
  }
});

export const {
  setResultData,
  clearResultData,
} = resultsSlice.actions;

export default resultsSlice.reducer;