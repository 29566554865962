import { useState } from 'react';
import {
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { setStrategy, isEdited } from '../../redux/configSlice';

import InfoButton from '../Template/Info';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
    marginRight: '10px',
    alignItems: 'center',
  },
  infoButton: {
    marginLeft: '5px',
  },
  text: {
    color: 'black',
  }
}))

function Info(props) {
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>What are "targeted" and "traditional" contributions?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A "targeted" contribution uses information about your asset balances to target
          your contribution towards those assets that are currently under their desired asset mix.
          The purpose of this strategy is to attempt to keep your portfolio balanced without incurring
          the taxes/fees associated with a rebalance by only buying assets that are at or below
          the desired asset mix. This strategy may also be useful when you change your asset
          mix/holdings and would prefer to attempt to gradually rebalance your portfolio. Note that
          this strategy may suggest selling tax-exempt assets to aid in maintaining portfolio balance
          but will not sell taxable assets.
        </DialogContentText>
        <DialogContentText>
          A "traditional" contribution is how people more traditionally contribute to their
          portfolio. Instead of relying on your current balances (including growth) for each
          asset, this strategy instead relies on your desired mix percentages to weight the
          allocation to each asset as well as your past traditional contribution history for a given
          desired asset mix. E.g. if your desired asset mix for assets A, B, and C are 15%, 75%, and
          10%, respectively, and you want to contribute $1000, it will attempt to allocate
          $150, $750, and $100 to each respective asset. However, proportional contributions
          may not always be possible with stocks/ETFs since it is usually not possible to buy
          fractional shares (mutual funds have a similar issue to this but it is less severe).
          To get around this, the contribution history for this 15%/75%/10% desired mix is tracked
          to ensure that contributions over time will converge to your desired asset mix. How are
          your contributions tracked? That is the purpose of the CYCLE button that will appear at
          the buttom of the page after clicking CALCULATE. For more information, see the <InfoButton />
          next to the CYCLE button.
        </DialogContentText>
        <DialogContentText>
          Which strategy is better is a matter of preference. The "traditional" strategy may lead to
          higher growth but the "targeted" strategy may be lower risk. A mixed approach may be to
          use a "traditional" strategy until one of the assets becomes, say 5%, out of balance
          when one could switch to the "targeted" strategy. Then, if an asset increases to 10%
          out of balance, one may consider doing a rebalance and switching back to the "traditional"
          strategy.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Strategy() {
  const {
    rebalance,
    contribute,
    strategy,
  } = useSelector(({ config }) => config);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [openInfo, setOpenInfo] = useState(false);

  const setColor = (selected) => selected ? 'primary' : 'default';

  const onClick = (state) => () => {
    if (strategy !== state) {
      dispatch(isEdited());
    }
    dispatch(setStrategy({ strategy: state }));
  }

  const display = (contribute === false || rebalance) ? { display: 'none' } : {};

  return (
    <>
      <Divider
        style={{ marginTop: '10px', marginBottom: '5px', ...display }}
      />
      <Typography
        className={classes.text}
        variant='subtitle'
        style={display}
      >
        <b>Contribution strategy</b>
      </Typography>
      <div className={classes.root}>
        <ButtonGroup
          style={display}
        >
          <Button
            variant='contained'
            color={setColor(strategy === 'targeted')}
            onClick={onClick('targeted')}
          >
            Targeted
          </Button>
          <Button
            variant='contained'
            color={setColor(strategy === 'traditional')}
            onClick={onClick('traditional')}
          >
            Traditional
          </Button>
        </ButtonGroup>
        <InfoButton
          className={classes.infoButton}
          onClick={() => setOpenInfo(true)}
          style={{ ...((contribute === false || rebalance) ? { display: 'none' } : {}) }}
        />
        <Info open={openInfo} setOpen={setOpenInfo} />
      </div>
    </>
  );
}