import { keyBy } from 'lodash';

import { convertAssetForRequest, convertAssetForResponse } from '../../utils';
import traditionalLib from '../../utils/traditionalLib';

export function preprocessData(assetData, contribute, strategy) {
  const keyedAssetData = keyBy(assetData.map(({
    continuous,
    taxable,
    balance,
    shares,
    price,
    name,
    desiredMix,
  }) => convertAssetForRequest({
    name,
    continuous,
    taxable,
    balance,
    shares,
    price,
    desiredMix,
  })), 'name');

  if (contribute && strategy === 'traditional') {
    const { assets } = traditionalLib.recall(keyedAssetData);
    return traditionalLib.preMerge(keyedAssetData, assets);
  }

  return keyedAssetData;
}

export function updateAssets(
  { data },
  contribute,
  strategy,
  assetData,
  setAssetData,
  setRemainder,
  setWithdrawn,
  setLastCalculation,
  setEdited,
  setThinking,
  setErrors,
  contributeState,
  withdrawState) {
  console.log(data);

  let { contributions, converged } = data;
  if (data.contributions === null) {
    contributions = {};
  }

  const cloned = assetData.map((asset) => {

    const {
      initialBalance,
      initialShares,
      deltaBalance,
      deltaShares,
      finalBalance,
      finalShares,
      initialMix,
      finalMix,
    } = convertAssetForResponse(
      contributions[asset.name] || { deltaBalance: 0, deltaShares: 0 }
    );
    return {
      ...asset,
      initialBalance,
      initialShares,
      deltaBalance,
      deltaShares,
      finalBalance,
      finalShares,
      initialMix,
      finalMix,
      ...(!asset.continuous && { requestPrice: asset.price }),
    };
  });

  if (contribute) {
    setRemainder({ remainder: data.remainder / 100.0 });
    setWithdrawn({ withdrawn: undefined });
    setLastCalculation({ lastCalculation: contributeState });
    if (contributeState !== 'rebalance' && strategy === 'traditional') {
      traditionalLib.stage(keyBy(cloned, 'name'));
    }
  } else {
    setWithdrawn({ withdrawn: data.withdrawn && data.withdrawn / 100.0 });
    setRemainder({ remainder: undefined });
    setLastCalculation({ lastCalculation: withdrawState });
  }
  if (converged === false) {
    setErrors(['Calculation did not converge. Results may be inaccurate.']);
  }
  setEdited(false);
  setThinking(false);
  return setAssetData({ resultData: cloned });
}