import { createSlice } from '@reduxjs/toolkit';

import { pullStorage } from '../utils/io';

export const configSlice = createSlice({
  name: 'config',

  initialState: {
    instructionsOpen: (() => {
      const value = pullStorage('instructionsOpen', JSON.parse);
      if (value === null || value) {
        return true;
      }
      return false;
    })(),
    edited: true,
    contribute: (() => {
      const value = pullStorage('contribute', JSON.parse);
      if (value === null || value) {
        return true;
      }
      return false;
    })(),
    amount: pullStorage('amount', JSON.parse) || 0.0,
    strategy: pullStorage('strategy') || 'targeted',
    rebalance: (() => {
      const value = pullStorage('rebalance', JSON.parse);
      if (value === null || value) {
        return true;
      }
      return false;
    })(),
    remainder: undefined,
    withdrawn: undefined,
    lastCalculation: undefined,
  },

  reducers: {
    openInstructions: (state) => {
      state.instructionsOpen = true;
      window.localStorage.setItem('instructionsOpen', 'true');
    },
    closeInstructions: (state) => {
      state.instructionsOpen = false;
      window.localStorage.setItem('instructionsOpen', 'false');
    },
    isEdited: (state) => {
      state.edited = true;
    },
    isNotEdited: (state) => {
      state.edited = false;
    },
    isContribute: (state) => {
      state.contribute = true;
      window.localStorage.setItem('contribute', true);
    },
    isNotContribute: (state) => {
      state.contribute = false;
      window.localStorage.setItem('contribute', false);
    },
    setAmount: (state, { payload = {} } = {}) => {
      state.amount = payload.amount;
      if (typeof payload.amount === 'number') {
        window.localStorage.setItem('amount', payload.amount);
      }
    },
    setStrategy: (state, { payload = {} } = {}) => {
      state.strategy = payload.strategy;
      window.localStorage.setItem('strategy', payload.strategy);
    },
    isRebalance: (state) => {
      state.rebalance = true;
      window.localStorage.setItem('rebalance', true);
    },
    isNotRebalance: (state) => {
      state.rebalance = false;
      window.localStorage.setItem('rebalance', false);
    },
    setRemainder: (state, { payload = {} } = {}) => {
      state.remainder = payload.remainder;
    },
    setWithdrawn: (state, { payload = {} } = {}) => {
      state.withdrawn = payload.withdrawn;
    },
    setLastCalculation: (state, { payload = {} } = {}) => {
      state.lastCalculation = payload.lastCalculation;
    }
  }
});

export const {
  openInstructions,
  closeInstructions,
  isEdited,
  isNotEdited,
  isContribute,
  isNotContribute,
  setAmount,
  setStrategy,
  isRebalance,
  isNotRebalance,
  setRemainder,
  setWithdrawn,
  setLastCalculation,
} = configSlice.actions;

export default configSlice.reducer;