import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: '#c62828',
    },
    secondary: {
      main: '#00796b',
    },
  },
});