import { identity } from 'lodash';

export function fileDownload(data, filename) {
  const blob = new Blob(
    [JSON.stringify(data)],
    { type: 'application/json' },
  );
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function asyncReadFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export async function readUpload(file) {
  return JSON.parse(await asyncReadFile(file));
}

export function pullStorage(key, parser = identity) {
  try {
    return parser(window.localStorage.getItem(key));
  } catch {
    // Item could not be parsed, just delete the item and treat like
    // an empty key.
    window.localStorage.removeItem(key);
    return null;
  }
}