import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { updateAsset } from '../../../redux/assetsSlice';
import { isEdited } from '../../../redux/configSlice';


export default function Taxable(props) {
  const { assetData } = useSelector(({ assets }) => assets);
  const dispatch = useDispatch();

  const { index } = props;

  const { name, taxable = true } = assetData[index];

  const updateTaxable = () => {
    dispatch(updateAsset({ updates: { taxable: !taxable }, index }));
    dispatch(isEdited());
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Tax/fee status</FormLabel>
        <RadioGroup
          value={taxable}
          onChange={updateTaxable}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label='Taxable'
            disabled={name === '$cash'}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label='Tax-exempt'
            disabled={name === '$cash'}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
