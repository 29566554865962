import { Grid, Box, Button, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export default function Data(props) {
  const { assetData } = useSelector(({ assets }) => assets);

  const classes = useStyles();

  const { index } = props;
  const { name } = assetData[index];

  const show = name !== '' && name !== '$cash' && name;

  const onClick = () => {
    window.open(`https://finance.yahoo.com/quote/${name}`, '_blank');
  };

  return (
    <Box
      className={classes.root}
      component={Grid}
      item
      xs={12}
      sm={6}
      md={4}
      {...(!show && { style: { display: 'none' } })}
    >
      <Button
        variant='outlined'
        onClick={onClick}
      >
        View quote
      </Button>
    </Box>
  )
}