import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from '@material-ui/core';

import Ticker from './Ticker';
import Taxable from './Taxable';
import Continuous from './Continuous';
import Balance from './Balance';
import Shares from './Shares';
import Price from './Price';
import DesiredMix from './DesiredMix';
import Data from './Data';

export default function EditAsset(props) {
  const {
    index,
    open,
    onClose,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Asset</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill out all of the fields for this asset.
        </DialogContentText>
        <Grid container spacing={5}>
          <Ticker
            index={index}
          />
          <Continuous
            index={index}
          />
          <Taxable
            index={index}
          />
        </Grid>
        <br />
        <Divider variant='middle' />
        <br />
        <Grid container spacing={5}>
          <Balance
            index={index}
          />
          <Shares
            index={index}
          />
          <Price
            index={index}
          />
          <Data
            index={index}
          />
        </Grid>
        <br />
        <Divider variant='middle' />
        <br />
        <Grid container spacing={5}>
          <DesiredMix
            index={index}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
