import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

export default function ConfirmClear(props) {
  const { open, setOpen, setAnchorEl } = props;

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClear = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Are you sure you would like to clear your data?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Clicking "yes" will clear your data from the page and the data on this page from
          your browser's storage and cannot be recovered. If you would like to download your
          data first, click "Export data" after clicking the <SettingsIcon /> button. You may
          re-import the exported data later using "Import data".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClear} color='primary'>Yes</Button>
        <Button onClick={handleClose} color='secondary' autoFocus>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}