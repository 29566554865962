import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { formatMoney, roundOut } from '../../utils';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginBottom: '5px',
  },
  buyText: {
    color: 'rgb(0,200,0)',
  },
  sellText: {
    color: 'rgb(200,0,0)',
  },
  passText: {
    color: 'rgb(0,0,0)',
  },
  stickyColumn: {
    // position: '-webkit-sticky',
    position: 'sticky',
    left: 0,
    zIndex: 3,
  }
}));

function Row(props) {
  const classes = useStyles();

  const {
    name,
    continuous,
    initialBalance,
    deltaBalance,
    finalBalance,
    initialShares,
    deltaShares,
    finalShares,
    initialMix,
    finalMix,
    desiredMix,
    requestPrice,
  } = props;

  const initialMixFormatted = initialMix !== undefined ?
    (initialMix * 100).toPrecision(4).toString() + '%' :
    undefined;
  const finalMixFormatted = finalMix !== undefined ?
    (finalMix * 100).toPrecision(4).toString() + '%' :
    undefined;
  const desiredMixFormatted = desiredMix !== undefined ?
    desiredMix.toPrecision(4).toString() + '%' :
    undefined;

  const addCurrency = (value) => {
    if (value !== undefined && value >= 0.0) {
      return `$${formatMoney(value)}`;
    }
    if (value !== undefined) {
      return `-$${formatMoney(-value)}`
    }
    return undefined;
  }

  const sharesToBalance = (shares, price) => {
    if (shares !== undefined && price !== undefined) {
      return addCurrency(roundOut(shares * price));
    }
    return undefined;
  }

  if (continuous) {
    let colorClass = classes.passText;
    if (deltaBalance < 0.0) {
      colorClass = classes.sellText;
    } else if (deltaBalance > 0.0) {
      colorClass = classes.buyText;
    }

    return (
      <TableRow key={name}>
        <TableCell className={classes.stickyColumn} style={{ background: '#fff', zIndex: 2 }}>{name}</TableCell>
        <TableCell>N/A</TableCell>
        <TableCell>{addCurrency(initialBalance)}</TableCell>
        <TableCell className={colorClass}>{addCurrency(deltaBalance)}</TableCell>
        <TableCell>{addCurrency(finalBalance)}</TableCell>
        <TableCell>N/A</TableCell>
        <TableCell>N/A</TableCell>
        <TableCell>N/A</TableCell>
        <TableCell>{initialMixFormatted}</TableCell>
        <TableCell>{finalMixFormatted}</TableCell>
        <TableCell>{desiredMixFormatted}</TableCell>
      </TableRow>
    );
  }

  let colorClass = classes.passText;
  if (deltaShares < 0.0) {
    colorClass = classes.sellText;
  } else if (deltaShares > 0.0) {
    colorClass = classes.buyText;
  }

  return (
    <TableRow key={name}>
      <TableCell className={classes.stickyColumn} style={{ background: '#fff', zIndex: 2 }}>{name}</TableCell>
      <TableCell>{addCurrency(requestPrice)}</TableCell>
      <TableCell>
        {sharesToBalance(initialShares, requestPrice)}
      </TableCell>
      <TableCell className={colorClass}>
        {sharesToBalance(deltaShares, requestPrice)}
      </TableCell>
      <TableCell>
        {sharesToBalance(finalShares, requestPrice)}
      </TableCell>
      <TableCell>{roundOut(initialShares, 3)}</TableCell>
      <TableCell className={colorClass}>{deltaShares}</TableCell>
      <TableCell>{roundOut(finalShares, 3)}</TableCell>
      <TableCell>{initialMixFormatted}</TableCell>
      <TableCell>{finalMixFormatted}</TableCell>
      <TableCell>{desiredMixFormatted}</TableCell>
    </TableRow>
  );
}

function Report(props) {
  const classes = useStyles();

  const { open, handleClose } = props;

  const { resultData } = useSelector(({ results }) => results);
  const { edited } = useSelector(({ config }) => config);

  return (
    <Dialog
      maxWidth='80%'
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Detailed results</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TableContainer component={Paper} style={{ overflowY: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableCell className={classes.stickyColumn}>Ticker</TableCell>
              <TableCell>Price/unit</TableCell>
              <TableCell>Initial balance</TableCell>
              <TableCell>Buy/sell balance</TableCell>
              <TableCell>Final balance</TableCell>
              <TableCell>Initial shares</TableCell>
              <TableCell>Buy/sell shares</TableCell>
              <TableCell>Final shares</TableCell>
              <TableCell>Initial mix</TableCell>
              <TableCell>Final Mix</TableCell>
              <TableCell>Desired mix</TableCell>
            </TableHead>
            <TableBody style={{ textDecoration: edited ? 'line-through' : 'none' }}>
              {resultData.filter(asset => asset.finalMix !== undefined)
                .map(asset => <Row {...asset} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ReportButton() {
  const classes = useStyles();

  const { edited } = useSelector(({ config }) => config);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={classes.button}
        variant='contained'
        color='secondary'
        onClick={handleClick}
        style={{ textDecoration: edited ? 'line-through' : 'none' }}
      >
        Detailed results
      </Button>
      <Report
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}