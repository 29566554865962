import { round } from 'lodash';

// Note that requestPrice is just a copy what price was used
// to calculate the results. Using price will cause the price in
// the result to be updated.
export const getDefault = () => ({
  name: '',
  continuous: true,
  taxable: true,
  desiredMix: 0.0,
  balance: 0.0,
  shares: 0.0,
  price: 0.01,
  requestPrice: 0.0,
});

export function deepCopy(value) {
  return JSON.parse(JSON.stringify(value));
}

export function onlyNumbers(value) {
  return value.replace(/[^0-9\.]/g, '');
}

export function roundOut(value, prec) {
  return value && round(value, prec);
}

export function onlyMath(value) {
  return value.replace(/[^0-9\+\-\(\)\/\*\.]/g, '');
}

export function toZero(value) {
  if (value === undefined || value === null ||
    (typeof value === 'string' && value.length === 0)) {
    return 0;
  }
  return value;
}

export function formatMoney(value) {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2
  });
}

export function convertAssetForRequest(asset) {
  const copy = deepCopy(asset);
  if (copy.continuous) {
    copy.balance = Math.round(100 * copy.balance);
    return copy;
  }
  copy.price = Math.round(100 * copy.price);
  return copy;
}

export function convertAssetForResponse(asset) {
  const copy = deepCopy(asset);
  if (copy.finalBalance !== undefined) {
    ['initialBalance', 'deltaBalance', 'finalBalance'].forEach((key) => {
      copy[key] = copy[key] / 100.0;
    });
    return copy;
  }
  copy.price = copy.price / 100.0;
  return copy;
}

export function bound(value, { lb, ub }) {
  if (lb !== undefined && value < lb) {
    return lb;
  }
  if (ub !== undefined && value > ub) {
    return ub;
  }
  return value;
}
