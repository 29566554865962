import { createSlice } from "@reduxjs/toolkit";

import { pullStorage } from "../utils/io";
import { getDefault } from "../utils";

export const assetsSlice = createSlice({
  name: 'assets',

  initialState: {
    assetData: pullStorage('assetData', JSON.parse) || [getDefault()],
  },

  reducers: {
    addAsset: (state) => {
      state.assetData.push(getDefault());
    },
    updateAsset: (state, { payload = {} } = {}) => {
      const { updates, index } = payload;
      state.assetData[index] = {
        ...state.assetData[index],
        ...updates,
      };
      window.localStorage.setItem(
        'assetData',
        JSON.stringify(state.assetData)
      );
    },
    deleteAsset: (state, { payload = {} } = {}) => {
      const { index } = payload;
      state.assetData.splice(index, 1);
      window.localStorage.setItem(
        'assetData',
        JSON.stringify(state.assetData)
      );
    },
    setAssetData: (state, { payload = {} } = {}) => {
      state.assetData = payload.assetData;
    },
  }
});

export const {
  addAsset,
  updateAsset,
  deleteAsset,
  setAssetData,
} = assetsSlice.actions;

export default assetsSlice.reducer;